import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {
  "title": "Terms and Conditions"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Terms and Conditions`}</h1>
    <p>{`We don't have those yet, sorry. But, we promise to not sell your data to
anyone, and to not use your data for anything else but the purpose of this
service, which is to help organize conscious events better!`}</p>
    <p><a parentName="p" {...{
        "href": "./about#Contact"
      }}>{`Contact us`}</a>{` with any questions.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      